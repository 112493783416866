.home-page {
  width: 100%;
  position: relative;
  height: 100%;
  background: var(--main-bg-1);
}
header {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1000;
}
.header-mob-icon {
  display: none;
}
.header-mob-icon span {
  font-size: 2.5rem;
  color: var(--main-pink);
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-mob-icon span:hover {
  color: var(--main-ferozi);
}
.header-mob {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background: #fff;
  overflow: hidden;
  display: none;
}
/* 
.hm-box-1 {
  width: 80vw;
  height: 200vh;
  background-color: var(--main-teal);
  position: absolute;
  top: -20vh;
  right: 40vw;
  transform: rotate(25deg);
  z-index: 8888;
}
.hm-box-2 {
  width: 200vw;
  height: 200vh;
  background-color: var(--main-pink);
  position: absolute;
  top: 0;
  right: 0vw;
  z-index: 7777;
}
.hm-box-3 {
  width: 100vw;
  height: 100vh;
  background-color: var(--main-blue);
  position: absolute;
  bottom: -20vh;
  right: -50vw;
  z-index: 8000;
  transform: rotate(25deg);
} */
.header-mob-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
  position: relative;
  z-index: 9999;
}
.header-mob-head img {
  height: 5rem;
}
.header-mob-body {
  width: 100%;
  position: relative;
  z-index: 9999;
  padding: 5rem 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-mob-body-content {
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.header-mob-body-content > * {
  margin: 0 0 4rem 0;
}
.header-mob-head span {
  height: max-content;
  width: max-content;
  display: flex;
  align-items: center;
  justify-self: center;
}
.header-mob-head span svg {
  font-size: 2.5rem;
  color: var(--main-blue);
}
.header-content {
  width: 100%;
  position: relative;
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-left {
  width: max-content;
  height: 5.5rem;
  display: flex;
  position: relative;
}
.header-left img {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  height: 5.5rem;
  object-fit: contain;
  z-index: 9;
  filter: var(--filter5);
}
.header-left div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  z-index: 10;
}
.header-center {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-center a {
  margin: 0 2.5rem;
  transition: 0.35s;
  text-decoration: none;
  position: relative;
}
.header-mob-body a {
  transition: 0.35s;
  position: relative;
}
.header-center a span {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-color-1);
  position: relative;
  transition: 0.35s;
  z-index: 10;
}
.header-mob-body a {
  font-size: 2.5rem;
  color: #000;
  position: relative;
  transition: 0.35s;
  z-index: 10;
  font-weight: 700;
  width: max-content;
  text-decoration: none;
}
.header-mob-body a:active,
.header-mob-body a:focus {
  color: #000;
}
.header-mob-body span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.header-mob-body span img {
  width: 7rem;
  margin: 0 1.5rem 0 0;
}
.header-center a div {
  content: "";
  width: 0;
  height: 1px;
  z-index: 9;
  background: linear-gradient(45deg, #62eaff, #ff6dac);
  position: absolute;
  bottom: 0px;
  left: 0;
  transition: 0.35s;
}
.header-center a:hover div {
  width: 100%;
}
.header-right {
  width: max-content;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.header-vertical-icons-box {
  width: max-content;
  position: relative;
  min-width: 5rem;
}
.header-vertical-icons {
  width: max-content;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
}
.header-vertical-icons a {
  margin: 0 0 1.5rem 0;
  cursor: pointer;
}
.header-vertical-icons svg {
  font-size: 2rem;
  color: var(--text-color-1);
  cursor: pointer;
}

.header-vertical-icons a:nth-child(1) svg:hover {
  color: var(--main-pink);
}
.header-vertical-icons a:nth-child(2) svg:hover {
  color: var(--main-ferozi);
}
.header-vertical-icons a:nth-child(3) svg:hover {
  color: var(--main-blue);
}
.header-vertical-icons div svg:hover {
  color: var(--main-black);
}
.hero-section {
  width: 100%;
  padding: 7.5rem 0 0 0;
  position: relative;
}
.hero-section-content {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  position: relative;
}
.hero-section-content h1 {
  font-size: 5rem;
  color: #353535;
  font-weight: 500;
  text-align: center;
}
.hero-section-content p {
  font-size: 2rem;
  margin: 4rem 0;
  font-weight: 400;
  text-align: center;
  color: #353535;
}
.hero-tags-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 6rem 0 0 0;
  position: relative;
  z-index: 1000;
}
.hero-tags-box span {
  font-size: 1.35rem;
  display: block;
  padding: 0.35rem 1.25rem;
  margin: 0 1.5rem;
  color: var(--text-color-3);
  border: 1px solid var(--text-color-3);
  border-radius: 1000px;
  background-color: #fff;
  font-weight: 500;
}
.bera-stats-section {
  width: 100%;
  padding: 15rem 0;
}

.bera-stats-parent-box-con {
  width: 100%;
  max-width: 850px;
  margin: 12.5rem auto 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}
.bera-stats-parent-box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}
.home-floating-bubble-1 {
  width: 2.5rem;
  position: absolute;
  top: -7.5rem;
  left: 5rem;
  object-fit: contain;
  filter: blur(3px);
}
.home-floating-bubble-2 {
  width: 2.5rem;
  position: absolute;
  top: -5rem;
  right: 5rem;
  object-fit: contain;
  filter: blur(3px);
}
.home-floating-bubble-3 {
  width: 2.5rem;
  position: absolute;
  bottom: -5rem;
  left: 10rem;
  object-fit: contain;
  filter: blur(3px);
  opacity: 0.75;
}
.home-floating-bubble-4 {
  width: 2.5rem;
  position: absolute;
  bottom: 0rem;
  right: 0rem;
  object-fit: contain;
  filter: blur(3px);
}
.home-floating-bubble-2 {
  width: 2.5rem;
  position: absolute;
  top: -5rem;
  right: 5rem;
  object-fit: contain;
  filter: blur(3px);
}
.bera-stats-number-box {
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 18.5rem;
}
.bera-stats-number-box:nth-child(2),
.bera-stats-number-box:nth-child(4) {
  justify-content: flex-end;
}
.bera-stats-number-box p {
  color: var(--text-color-2);
  font-size: 1.5rem;
}
.bera-stats-number-box h4 {
  color: var(--text-color-1);
  font-size: 3.25rem;
  font-weight: 500;
}
.total-value-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10rem 0 0 0;
  justify-content: center;
}
.total-value-box h6 {
  font-size: 1.65rem;
  font-weight: 400;
  color: var(--text-color-3);
}
.total-value-box h4 {
  font-size: 4rem;
  font-weight: 500;
  background: linear-gradient(45deg, #3ec3d7, #ff388e);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: var(--filter5);
  color: var(--main-ferozi);
}
.home-left-shapes-box {
  width: max-content;
  height: max-content;
  position: absolute;
  top: 0;
  right: 10rem;
  min-width: 125px;
  min-height: 125px;
}
.home-l1-img {
  width: 16.5rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10 !important;
}
.home-l2-img {
  width: 8.5rem;
  position: absolute;
  bottom: 0;
  right: 0rem;
  z-index: 11 !important;
}
.home-bl-shapes-box {
  width: max-content;
  height: max-content;
  position: absolute;
  bottom: 0rem;
  left: 15rem;
  min-width: 150px;
  min-height: 150px;
}
.home-bl1-img {
  width: 19rem;
  position: absolute;
  top: 0;
  left: 0;
}
.home-bl2-img {
  width: 7.5rem;
  position: absolute;
  top: 3rem;
  right: 2rem;
}
.home-right-shapes-box {
  width: max-content;
  height: max-content;
  position: absolute;
  top: 2.5rem;
  right: 7.5rem;
  min-width: 150px;
  min-height: 150px;
}
.home-r1-img {
  width: 2.5rem;
  position: absolute;
  top: -0.5rem;
  left: 2rem;
  z-index: 10;
}
.home-r2-img {
  width: 15rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}
.home-r3-img {
  width: 8.5rem;
  position: absolute;
  bottom: -3rem;
  right: 0rem;
  z-index: 10;
}

.home-br-shapes-box {
  width: max-content;
  height: max-content;
  position: absolute;
  bottom: -5rem;
  left: 10rem;
  min-width: 200px;
  min-height: 200px;
}
.home-br1-img {
  width: 7.5rem;
  position: absolute;
  top: 3rem;
  left: 0;
  z-index: 10;
}
.home-br2-img {
  width: 20rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}

.light-dark-theme-box {
  width: max-content;
  height: max-content;
  padding: 0.25rem;
  border-radius: 1rem;
  background-color: var(--main-pink);
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 2.5rem 0 0;
  z-index: 15;
  position: relative;
}
.theme-icon {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
  cursor: pointer;
}
.theme-icon svg {
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
}
.active-theme.theme-icon {
  background: var(--main-ferozi);
}
.active-theme.theme-icon svg {
  color: #fff;
}
.header-right-box {
  width: max-content;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1150px) {
  .home-right-shapes-box {
    top: 50%;
    right: 3.5rem;
  }
  .home-br-shapes-box {
    bottom: -40%;
    left: 5rem;
    scale: 0.85;
  }
}
@media screen and (max-width: 1000px) {
  .header-center,
  .header-right {
    display: none;
  }
  .home-right-shapes-box {
    scale: 0.65;
  }
  .home-bl-shapes-box {
    left: 5rem;
    scale: 0.85;
  }
  .home-right-shapes-box {
    right: 5.5rem;
  }
  .header-mob,
  .header-mob-icon {
    display: flex;
  }
}
@media screen and (max-width: 850px) {
  .header-left img {
    height: 5rem;
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }
  .header-content {
    padding: 2rem 0;
  }
  .hero-section-content h1 {
    font-size: 3.75rem;
  }
  .hero-section-content p {
    font-size: 1.75rem;
    margin: 5rem 0;
  }
  .bera-stats-section {
    padding: 10rem 0;
  }
  .home-right-shapes-box,
  .home-br-shapes-box,
  .home-bl-shapes-box,
  .home-left-shapes-box {
    display: none;
  }
  .hero-tags-box span {
    margin: 0 1rem 1rem 1rem;
  }
  .hero-section-content h1 {
    font-size: 4rem;
  }
  .total-value-box h4 {
    font-size: 3.5rem;
  }
  .bera-stats-number-box h4 {
    font-size: 3rem;
  }
  .bera-stats-parent-box {
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 1.5rem; */
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .bera-stats-number-box {
    min-height: max-content;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column-reverse;
    transform: translate(0, 0) !important;
    width: 50%;
    margin: 0 0 5rem 0;
  }
  .bera-stats-number-box h4 {
    margin: 0;
    font-size: 4rem;
  }
  .bera-stats-number-box p {
    margin: 0.5rem 0 0 0;
  }
  .bera-stats-number-box:last-child {
    width: 100%;
  }
  .total-value-box {
    margin: 5rem 0 0 0;
  }
}
