.farms-top {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
  padding: 0 3rem 1.5rem 0;
}
.farms-top-text > * {
  width: max-content;
  text-align: left !important;
}
.farms-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.farms-head .nav-tabs {
  margin: 0 6rem 0 0;
}

.farms-page .form-switch {
  transform: scale(1.75);
  cursor: pointer;
  color: var(--text-color-2);
}
.form-check-input:checked {
  background-color: var(--main-pink);
}
.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(228, 142, 182, 0);
}
#custom-switch {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  border-color: var(--main-ferozi);
  cursor: pointer;
  background-color: var(--main-ferozi);
}
#custom-switch:checked {
  border-color: var(--main-pink);
  background-color: var(--main-pink);
}
.farms-card-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 1.5rem 0;
}
.fct-left {
  width: max-content;
}
.fct-left div {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fct-left div img {
  width: 3rem;
  height: 3rem;
  margin: 0 0f5rem 0 0;
  object-fit: contain;
}
.fct-left h6 {
  font-size: 1.65rem;
  font-weight: 700;
  margin: 1rem 0 0 0;
  color: var(--text-color-2);
}
.fct-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.fct-right span {
  color: var(--main-white);
  font-size: 1.35rem;
  background-color: var(--main-blue);
  padding: 0.1rem 1rem;
  width: max-content;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
}
.fct-right p {
  font-size: 1.35rem;
  display: flex;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
  color: var(--main-pink);
  margin: 0.5rem 0 0 0;
}
.fct-right p svg {
  font-size: 1.5rem;
  margin: 0 0.5rem 0 0;
}

/* MINT NEW  */
.plus-minus-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.5rem 0;
}
.plus-minus-box button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-ferozi);
  border-radius: 0.75rem;
  width: 3rem;
  height: 3rem;
  outline: 0;
  border: 0;
}
.minus-box svg {
  font-size: 3rem;
  color: var(--main-blue);
}
.plus-box svg {
  font-size: 2.5rem;
  color: var(--main-pink);
}
.plus-minus-box p {
  font-size: 2rem;
  margin: 0 2.5rem;
  font-weight: 600;
  color: var(--text-color-3);
}
.bb-btn-grd-fill.mintnew-btn {
  padding: 1.15rem 2rem;
  border-radius: 1.5rem;
  width: max-content;
  min-width: 18.5rem;
  transform: scale(1.1);
  margin: 2.5rem 0;
  font-size: 1.75rem;
}
.bb-btn-grd-fill.mintnew-btn span {
  font-size: 2rem;
}
.mint-progress-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2.5rem 0 0 0;
  width: 100%;
  max-width: 400px;
}
.mint-progress {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 3.5rem;
  background-color: var(--main-white);
  border-radius: 0.75rem;
  border: 1px solid var(--main-pink);
}
.inner-progress {
  width: 60%;
  background-color: var(--main-pink);
  height: 3.5rem;
  border-radius: 0.75rem 0 0 0.75rem;
}
.mint-progress-box p {
  font-size: 1.65rem;
  margin: 1rem 0 0 0;
  font-weight: 500;
  color: var(--text-color-3);
}
.stake-main-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 3.5rem;
  padding: 5rem 0 0 0;
}
.stake-card label {
  width: 100%;
}
.stake-card input[type="radio"] {
  display: none;
}
.light-theme .stake-card .stake-card-content {
  border: 5px solid transparent;
}
.dark-theme .stake-card .stake-card-content {
  border: 5px solid #526169;
}
.stake-card .stake-card-content {
  display: block;
  background: var(--main-bg-1);
  border-radius: 1rem;
  box-shadow: 0 0 7px rgb(0 0 0 / 8%);
  position: relative;
  padding: 1.5rem;
  overflow: hidden;
  width: 100%;
  transition: 0.35s;
}
.stake-card input:checked + label .stake-card-content {
  border: 5px solid var(--main-pink);
}
.stake-card {
  width: 100%;
}
.stake-card video {
  width: 100%;
  object-fit: cover;
  max-height: 24rem;
  border-radius: 1rem;
}
.stake-card button {
  width: 100%;
  margin: 2.5rem 0 0 0;
}
.stake-card .video-box {
  width: 100%;
  position: relative;
}
.stake-card .video-box .play-btn {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--main-pink);
  font-size: 2.25rem;
  color: #fff;
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  cursor: pointer;
  z-index: 10;
}
.stake-card .video-box .pause-btn {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--main-ferozi);
  font-size: 2.25rem;
  color: #fff;
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  cursor: pointer;
  z-index: 10;
}
.mint-grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5rem 0 0 0;
}
.mint-card {
  width: 100%;
  max-width: 400px;
  border-radius: 1rem;
  box-shadow: 0 0 7px rgb(0 0 0 / 8%);
  position: relative;
  padding: 2.5rem;
  background: var(--main-bg-1);
}
.mint-card video {
  width: 100%;
  object-fit: cover;
  max-height: 30rem;
  border-radius: 1rem;
}
.mint-card > button {
  width: 100%;
  margin: 2.5rem 0 0 0;
}
.farms-head.mob-farm-head {
  display: none;
}
.farms-page {
  width: 100%;
  padding: 13.5rem 0 0 0;
  background: var(--main-bg-1);
}
.mint-left-shapes-box {
  width: max-content;
  height: max-content;
  position: absolute;
  top: 30rem;
  left: 7.5rem;
  min-width: 250px;
  min-height: 250px;
  z-index: 500;
}
.mint-l1-img {
  width: 100%;
  position: absolute;
  top: 1rem;
  left: 2rem;
}
.mint-l2-img {
  width: 100%;
  position: absolute;
  top: 0;
}
.mint-l3-img {
  width: 6.5rem;
  position: absolute;
  top: 3.5rem;
  left: 2rem;
}
.mint-l4-img {
  width: 15rem;
  position: absolute;
  bottom: 2rem;
  left: -2.5rem;
}
.mint-l5-img {
  width: 10rem;
  position: absolute;
  top: 50%;
  right: -15%;
}
.mint-right-shapes-box {
  width: max-content;
  height: max-content;
  position: absolute;
  top: 65rem;
  right: 7.5rem;
  min-width: 175px;
  min-height: 175px;
  z-index: 500;
}
.mint-r1-img {
  width: 100%;
  position: absolute;
  top: 0;
}
.mint-r2-img {
  width: 100%;
  position: absolute;
  top: 2rem;
  left: 1rem;
}
.mint-r3-img {
  width: 5rem;
  position: absolute;
  top: 8rem;
  right: -0.5rem;
}
.mint-r4-img {
  width: 8.5rem;
  position: absolute;
  bottom: -8rem;
  left: 2.5%;
}
.stake-left-shapes-box {
  width: max-content;
  height: max-content;
  position: absolute;
  top: 30rem;
  left: 3.5rem;
  min-width: 150px;
  min-height: 150px;
  z-index: 500;
}
.stake-l1-img {
  width: 100%;
  position: absolute;
  top: 1.5rem;
  left: 0;
}
.stake-l2-img {
  width: 100%;
  position: absolute;
  top: 0;
}
.stake-l3-img {
  width: 7.5rem;
  position: absolute;
  top: -2.5rem;
  left: 4.5rem;
}
.stake-l4-img {
  width: 5rem;
  position: absolute;
  bottom: 3.5rem;
  right: 35%;
}
.stake-right-shapes-box {
  width: max-content;
  height: max-content;
  position: absolute;
  top: 65rem;
  right: 5rem;
  min-width: 150px;
  min-height: 150px;
  z-index: 500;
}
.stake-r1-img {
  width: 100%;
  position: absolute;
  top: 0;
}
.stake-r2-img {
  width: 4rem;
  position: absolute;
  top: 0;
  right: 1.5rem;
}
.stake-r3-img {
  width: 10rem;
  position: absolute;
  bottom: -1.5rem;
  left: -1.5rem;
}
.farms-left-shapes-box {
  width: max-content;
  height: max-content;
  position: absolute;
  top: 30rem;
  left: 3.5rem;
  min-width: 175px;
  min-height: 175px;
  z-index: 500;
}
.farms-l1-img {
  width: 100%;
  position: absolute;
  top: -0.5rem;
  left: -1.2rem;
}
.farms-l2-img {
  width: 100%;
  position: absolute;
  top: 0;
}
.farms-l3-img {
  width: 5rem;
  position: absolute;
  bottom: 5rem;
  left: 3.5rem;
}
.farms-l4-img {
  width: 7rem;
  position: absolute;
  top: -2rem;
  right: 5rem;
}
.farms-right-shapes-box {
  width: max-content;
  height: max-content;
  position: absolute;
  top: 65rem;
  right: 5rem;
  min-width: 150px;
  min-height: 150px;
  z-index: 500;
}
.farms-r1-img {
  width: 100%;
  position: absolute;
  top: 0;
}
.farms-r2-img {
  width: 5rem;
  position: absolute;
  top: -2.5rem;
  left: 6.5rem;
}
.farms-r3-img {
  width: 10rem;
  position: absolute;
  bottom: 1rem;
  left: -2.5rem;
}
.downloadables-grid {
  width: 100%;
  padding: 2.5rem 0 0 0;
}
.downloadable {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.downloadable:not(:last-child) {
  margin: 0 0 2.5rem 0;
}
.downloadable svg {
  font-size: 2rem;
  color: var(--main-pink);
  margin: 0 1.5rem 0 0;
  transition: 0.35s;
}
.downloadable:hover svg {
  color: var(--main-ferozi);
}
.downloadable p {
  font-size: 1.65rem;
  margin: 0;
  color: var(--text-color-2);
}
@media screen and (max-width: 1400px) {
  .stake-left-shapes-box,
  .stake-right-shapes-box,
  .farms-left-shapes-box,
  .farms-right-shapes-box {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .mint-left-shapes-box,
  .mint-right-shapes-box {
    display: none;
  }
}
@media screen and (max-width: 1000px) {
  .stake-main-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 850px) {
  .farms-page {
    padding: 10rem 0 0 0;
  }
  .stake-main-grid {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 650px) {
  .farms-top {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 1.5rem 0;
  }
  .farms-top-text {
    margin: 0 0 3rem 0;
    text-align: center;
    width: 100%;
  }
  .berahouse-box h1 {
    width: 100%;
  }
  .berahouse-box h2 {
    width: 100%;
    text-align: center !important;
  }
  .farms-head.mob-farm-head {
    justify-content: flex-start !important;
  }
  .farms-head.mob-farm-head .nav-tabs {
    margin: 0 4rem 0 0;
  }
  .farms-head {
    display: none;
  }
  .farms-head.mob-farm-head {
    display: flex;
  }
  .berahouse-grid {
    margin: 5rem auto 0 auto;
  }
}
