.berahouse-box {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 2.5rem 0;
}
.berahouse-box h1 {
  font-size: 5.5rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--text-color-1);
  margin: 0 0 0.5rem -0.5rem;
  height: 4.75rem;
  /* background-color: red; */
}
.berahouse-box h1 div {
  width: 4.25rem;
  height: 4.25rem;
  margin: 0 0 0 1rem;
  /* background-color: green; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.berahouse-box h1 div img {
  height: 100%;
  object-fit: contain;
}
.berahouse-box h2 {
  font-size: 1.85rem;
  text-align: center;
  font-weight: 400;
  margin: 0;
  color: var(--text-color-3);
  text-transform: lowercase;
}

.berahouse-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 2.5rem;
  margin: 7.5rem auto 0 auto;
}
.berahouse-grid-card {
  width: 100%;
  padding: 2.5rem 2.5rem;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.075);
  position: relative;
  background: var(--main-bg-1);
}
.berahouse-grid-card > h3 {
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  color: var(--text-color-2);
}
.bgc-arrow-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 2.5rem 0;
}
.bgc-logo-img {
  width: 3.5rem;
  object-fit: contain;
}
.bgc-arrow-img {
  height: 1.65rem;
  object-fit: contain;
}
.bgc-data-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0.75rem 0;
}
.bgc-data-box.bgc-data-box-special p,
.bgc-data-box.bgc-data-box-special h6 {
  color: var(--main-pink) !important;
}
.bgc-data-box p {
  font-size: 1.65rem;
  font-weight: 400;
  color: var(--text-color-2);
  margin: 0;
}
.bgc-data-box h6 {
  font-size: 1.65rem;
  font-weight: 700;
  margin: 0;
  color: var(--text-color-2);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bgc-data-box h6 svg {
  font-size: 2rem;
  margin: 0 0.75rem 0 0;
  cursor: pointer;
}
.bgc-data-box h6 span:hover svg {
  color: var(--main-pink);
}
.bgc-data-box h6 a {
  display: flex;
  color: var(--text-color-2);
  align-items: center;
  justify-content: center;
  transition: 0.35s;
}
.bgc-data-box h6:hover a {
  color: var(--main-ferozi);
}
.bgc-data-box h6 a svg {
  font-size: 2rem;
  margin: 0 0 0 0.5rem;
}
.bgc-btn-box > span {
  font-size: 1.35rem;
  color: var(--text-color-2);
  font-weight: 400;
  text-transform: uppercase;
  display: block;
  margin: 0 0 1rem 0;
}
.bgc-btn-box > button {
  width: 100%;
}
.bgc-btn-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0 0 0;
}
.bgc-divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 2.5rem 0;
}
.berahouse-grid-card h5 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.75rem;
  color: var(--text-color-2);
  cursor: pointer;
  transition: 0.35s;
  margin: 0;
}
.berahouse-grid-card h5:hover {
  color: var(--main-pink);
}
.berahouse-grid-card h5 svg {
  font-size: 2.25rem;
  margin: 0.35rem 0 0 0rem;
}
.bgc-details-box > a {
  font-size: 1.5rem;
  color: var(--main-pink);
  transition: 0.35s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: max-content;
}
.bgc-details-box > a svg {
  transition: 0.35s transform;
  font-size: 1.5rem;
  margin: 0 0 0 0.25rem;
}
.bgc-details-box > a:hover {
  color: var(--main-pink);
}
.bgc-details-box > a:hover svg {
  transform: translate(0.25rem, -0.25rem);
}
.bgc-details-box {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 1.5rem;
  padding: 2.5rem;
  background: linear-gradient(
    45deg,
    rgba(98, 234, 255, 0.5),
    rgba(255, 109, 172, 0.5)
  );
  /* background: rgba(255, 255, 255, 0.75); */
  backdrop-filter: blur(10px);
  z-index: 100;
  box-shadow: 0 -10px 15px rgba(0, 0, 0, 0.05);
  /* transform: translate(0, 25rem); */
}
.bgc-details-box h5 {
  margin: 0 0 2.5rem 0;
}

.bgc-details-box h5:hover {
  color: #fff;
}
.dividends-content-box {
  width: 100%;
  margin: 7.5rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dividends-content-box > h6,
.buy-sell-content-box > h6 {
  text-align: center;
  font-size: 1.75rem;
  font-weight: 400;
  color: var(--text-color-2);
}
.buy-sell-content-box h6 b {
  font-weight: 700;
}
.farms-top-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.dividends-content-box .berahouse-grid-card {
  max-width: 400px;
  margin: 5rem 0 0 0;
}
.bonds-container {
  width: 100%;
  margin: 7.5rem 0 0 0;
}
.buy-sell-content-box {
  width: 100%;
  max-width: 550px;
  margin: 7.5rem auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.buysell-card {
  width: 100%;
  max-width: 400px;
  width: 100%;
  background-color: #fff;
  padding: 2.5rem 2.5rem;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.07);
  position: relative;
  margin: 5rem 0;
}
.buysell-card h5 {
  font-size: 2.5rem;
  font-weight: 400;
  color: var(--text-color-1);
  margin: 0 0 2.5rem 0;
}
.buysell-box-invest {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f7f8f9;
  padding: 1.5rem;
  border-radius: 1rem;
  margin: 0 0 2.5rem 0;
}
.buysell-box-invest > span {
  font-size: 1.35rem;
  color: var(--text-color-2);
  font-weight: 400;
  text-transform: uppercase;
  display: block;
  margin: 0 0 1rem 0;
}
.buysell-box-invest button {
  width: 100%;
}

@media screen and (max-width: 1150px) {
  .berahouse-box {
    max-width: 100%;
    margin: 0 5rem;
    width: unset;
  }
}

@media screen and (max-width: 850px) {
  .berahouse-box h1 {
    font-size: 5rem;
  }
  .berahouse-box h1 svg {
    width: 3rem;
  }
  .berahouse-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 650px) {
  .berahouse-box h1 {
    justify-content: center;
    font-size: 3.75rem;
  }
  .berahouse-box h1 svg {
    width: 2.5rem;
  }
  .berahouse-grid {
    grid-template-columns: 1fr;
  }
  .berahouse-box {
    margin: 0 2rem;
  }
  .farms-top-text h1 img {
    width: 100%;
  }
  .farms-top-text h1 img.mint-icon-img {
    width: 100%;
  }
  .farms-top-text h1 img.farm-icon-img {
    width: 100%;
  }
  .berahouse-box h1 div img {
    height: 80%;
  }
}
