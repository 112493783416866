.light-theme {
  --main-bg-1: #feffff;
  --text-color-1: #353535;
  --text-color-2: #5e6364;
  --text-color-3: #748082;
  --filter1: saturate(1);
  --filter2: saturate(1);
  --filter3: saturate(1);
  --filter4: saturate(1);
  --filter5: saturate(1);
}
.dark-theme {
  --main-bg-1: linear-gradient(45deg, #3c464b, #526169, #718591);
  --text-color-1: #fff;
  --text-color-2: #fff;
  --text-color-3: #fff;
  --filter1: saturate(1.1);
  --filter2: saturate(1.2);
  --filter3: saturate(1.3);
  --filter4: saturate(1.4);
  --filter5: saturate(1.5);
}
/* 
filter: var(--filter);
 */
