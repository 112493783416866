.coming-soon-page {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.cs-grd-circle-1 {
  width: 35rem;
  height: 35rem;
  border-radius: 50%;
  position: absolute;
  top: -15%;
  right: -15%;
  background-color: rgba(193, 67, 128, 0.15);
  z-index: 10;
  filter: blur(65px);
}
.cs-grd-circle-2 {
  width: 35rem;
  height: 35rem;
  border-radius: 50%;
  position: absolute;
  bottom: -15%;
  left: -15%;
  background-color: rgba(52, 69, 155, 0.15);
  z-index: 10;
  filter: blur(65px);
}
.coming-soon-wrapper {
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.015);
  z-index: 20;
  position: relative;
  overflow: hidden;
}
.cs-header {
  width: 100%;
  padding: 3.5rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;
}
.cs-header a img {
  height: 6rem;
  object-fit: contain;
}
.cs-header-icons-box {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.bera-eyes-open {
  width: 55%;
  position: absolute;
  top: 0;
  left: 7rem;
  z-index: 15;
  transition: 0.25s;
}
.bera-eyes-closed {
  width: 55%;
  position: absolute;
  top: 0px;
  left: 7rem;
  opacity: 1;
  z-index: 14;
}
.cs-babybera-box:hover .bera-eyes-open {
  opacity: 1 !important;
}
.cs-header-icons-box a {
  width: max-content;
}
.cs-header-icons-box svg {
  margin: 0 0 0 2.5rem;
  width: 2rem;
  height: 2rem;
  object-fit: contain;
  color: #353535;
  transition: 0.25s;
  cursor: pointer;
}
.cs-header-icons-box a:nth-child(1) svg:hover {
  color: var(--main-pink);
}
.cs-header-icons-box a:nth-child(2) svg:hover {
  color: var(--main-ferozi);
}
.cs-header-icons-box a:nth-child(3) svg:hover {
  color: var(--main-blue);
}
.cs-header-icons-box div svg:hover {
  color: var(--main-black);
}
.cs-heading-box {
  width: max-content;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 2.5rem 0 2.5rem 0;
  position: relative;
}
.cs-heading-box h1 {
  font-size: 5rem;
  line-height: 4rem;
  margin: 0 1rem 0 0;
  color: var(--text-color-1);
}
.cs-heading-box .cs-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin: 0 0.65rem 0 0;
}
.cs-dot-box .cs-dot:nth-child(1) {
  background-color: var(--main-pink);
}
.cs-dot-box .cs-dot:nth-child(2) {
  background-color: var(--main-ferozi);
}
.cs-dot-box .cs-dot:nth-child(3) {
  background-color: var(--main-blue);
}
.cs-dot-box {
  width: max-content;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(100%, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}
.cs-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 25;
}
.home-down-arrow {
  position: absolute;
  bottom: 2.5rem;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1000;
}
.home-down-arrow svg {
  font-size: 2.5rem;
}
.home-hero-cs-box {
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cs-content > img {
  width: 100%;
  max-width: 25rem;
  object-fit: contain;
  margin: 0 0 2.5rem 0;
  padding: 0.5rem;
}
.cs-berachain-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cs-berachain-box span {
  font-size: 1.85rem;
  color: var(--text-color-1);
}
.cs-berachain-box img {
  width: 2.85rem;
  margin: 0 0 0 0.85rem;
  filter: var(--filter3);
  transform: translate(0, 2px);
}
.cs-babybera-box {
  transition: 0.5s;
  width: max-content;
  position: relative;
}
.bera-desk {
  width: 35rem;
}
.cs-shapes {
  object-fit: contain;
  z-index: 20 !important;
  /* transform: scale(1); */
}
.cs-l1-img {
  width: 26.5rem;
  position: absolute;
  top: 0;
  /* left: 10%; */
}
.cs-l2-img {
  width: 26.5rem;
  position: absolute;
  top: 0;
  left: 3rem;
}
.cs-l3-img {
  width: 6.5rem;
  position: absolute;
  top: -2.5rem;
  left: 18.5%;
}
.cs-l4-img {
  width: 6rem;
  position: absolute;
  top: 40%;
  left: 25%;
}
.cs-l5-img {
  width: 10.5rem;
  position: absolute;
  top: 0.5rem;
  right: 2.5rem;
}
.cs-r1-img {
  width: 22.5rem;
  position: absolute;
  top: 5.5rem;
  right: -6.5%;
}
.cs-r2-img {
  width: 22.5rem;
  position: absolute;
  top: 0;
  right: 0%;
}
.cs-r3-img {
  width: 6rem;
  position: absolute;
  top: 7%;
  left: 30%;
}
.cs-r4-img {
  width: 12rem;
  position: absolute;
  top: 10%;
  right: -7.5%;
}
.cs-r5-img {
  width: 12.5rem;
  position: absolute;
  top: 55%;
  right: 10%;
}
.cs-left-shapes-box {
  width: max-content;
  height: max-content;
  position: absolute;
  bottom: -6.5rem;
  left: 5rem;
  min-width: 350px;
  min-height: 350px;
  z-index: 500;
}
.cs-right-shapes-box {
  width: max-content;
  height: max-content;
  position: absolute;
  bottom: 15rem;
  right: 10rem;
  min-width: 350px;
  min-height: 350px;
}
.cs-top-shapes-box {
  width: max-content;
  height: max-content;
  position: absolute;
  top: 17.5rem;
  left: 20rem;
  min-width: 250px;
  min-height: 250px;
}
.cs-next-text-box {
  width: max-content;
  height: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin: 0 0.5rem 0 0;
}

@media screen and (min-width: 768px) {
  .cs-next-text-box {
    min-width: 10rem;
  }
}

.cs-next-text-box span {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 2.75rem;
  z-index: 9;
  position: relative;
  text-align: right;
}
.cs-next-text-box .swiper {
  max-width: 10rem;
  position: relative;
  max-height: 2.75rem;
  overflow: hidden;
}
.cs-next-text-box .swiper::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.cs-t1-img {
  width: 15rem;
  position: absolute;
  top: 0;
  left: 0;
}
.cs-t2-img {
  width: 15rem;
  position: absolute;
  top: -2rem;
  left: 0.25rem;
}
.cs-t3-img {
  width: 5rem;
  position: absolute;
  top: 5rem;
  left: -1.5rem;
}
.cs-t4-img {
  width: 8.5rem;
  position: absolute;
  top: 7rem;
  right: 5rem;
}
/* .mint-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */
.mint-content > button {
  width: max-content;
  min-width: 18.5rem;
  transform: scale(1.1);
  margin: 2.5rem 0 0 0;
}
.cs-left-shapes-box-pc {
  display: block;
}
.cs-left-shapes-box-mob {
  display: none;
}
.mint-page header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
}
/* .cs-right-shapes-box,
.cs-top-shapes-box,
.cs-left-shapes-box.cs-left-shapes-box-pc,
.home-left-shapes-box,
.home-bl-shapes-box {
  filter: var(--filter2);
} */
@media screen and (max-width: 1350px) {
  .cs-left-shapes-box {
    bottom: -8.5rem;
    left: 0;
    transform: scale(0.85);
  }
  .cs-top-shapes-box {
    top: 20rem;
    left: 10rem;
    transform: scale(0.85);
  }
  .cs-right-shapes-box {
    bottom: 25%;
    right: 5rem;
    transform: scale(0.85);
  }
}
@media screen and (max-width: 1150px) {
  .cs-left-shapes-box {
    left: 0;
    transform: scale(0.75);
  }
  .cs-top-shapes-box {
    top: 20rem;
    left: 5rem;
    transform: scale(0.75);
  }
  .cs-right-shapes-box {
    right: 0rem;
    transform: scale(0.7);
  }
}
@media screen and (max-width: 1000px) {
  .cs-top-shapes-box {
    display: none;
  }
  .cs-left-shapes-box {
    bottom: -10rem;
    left: -2.5rem;
    transform: scale(0.65);
  }
  .cs-right-shapes-box {
    bottom: 50%;
    right: -6.5rem;
    transform: scale(0.5);
  }
  .cs-content {
    transform: translate(-50%, -50%) scale(0.9);
  }
  .cs-content-home.cs-content {
    padding: 15rem 0 0 0;
  }
}
@media screen and (min-width: 750px) {
  .cs-content-home.cs-content {
    position: relative;
    top: unset;
    right: unset;
    left: unset;
    transform: translate(0, 0);
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}
@media screen and (max-width: 750px) {
  .cs-content-home.cs-content {
    padding: 0;
  }
  .home-hero-cs-box {
    height: 95vh;
    display: block;
  }
  /* .bera-desk {
    width: 32.5rem;
  } */
  .home-down-arrow {
    display: none;
  }
  .cs-left-shapes-box {
    min-height: 300px;
  }
  .cs-dot-box {
    transform: translate(100%, -0.5rem);
  }
  .cs-left-shapes-box-pc {
    display: none;
  }
  .cs-left-shapes-box-mob {
    display: block;
  }
  .cs-content {
    transform: scale(0.75);
    top: unset;
    left: unset;
    position: relative;
  }
  .coming-soon-page {
    height: max-content;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .cs-header {
    position: relative;
    top: unset;
    left: unset;
  }
  .coming-soon-wrapper {
    height: unset;
  }
  .cs-heading-box h1 {
    font-size: 4rem;
  }
  .cs-heading-box .cs-dot {
    width: 0.85rem;
    height: 0.85rem;
  }
  .cs-left-shapes-box {
    bottom: unset;
    left: unset;
    transform: scale(0.8) !important;
    position: relative;
    margin: 1.5rem 0 0 0;
  }
  .cs-content.cs-content-home .cs-left-shapes-box {
    transform: scale(1) !important;
    margin: 15rem 0 0 0;
  }
  /*  .cs-left-shapes-box > * {
    transform: scale(0.75);
  } */
  .cs-right-shapes-box {
    bottom: 55%;
    right: -11.5rem;
    transform: scale(0.35);
    display: none;
  }
  .cs-header a img {
    height: 5rem;
  }
  .cs-header-icons-box svg {
    margin: 0 0 0 2rem;
    width: 1.85rem;
    height: 1.85rem;
  }
  .cs-header {
    padding: 2rem 0;
  }
  .box {
    padding: 0 2rem;
  }
}

.cs-discord-box:hover {
  opacity: 0.7;
}

.cs-discord-box img {
  max-width: 300px;
}

.cs-discord-box {
  text-decoration: none;
  text-align: center;
  display: block;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
