@font-face {
  font-family: "FuturaLT";
  src: url("../assets/fonts/FuturaLT/normal/FuturaLT-200.ttf") format("ttf");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "FuturaLT";
  src: url("../assets/fonts/FuturaLT/normal/FuturaLT-300.ttf") format("ttf");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "FuturaLT";
  src: url("../assets/fonts/FuturaLT/normal/FuturaLT-400.ttf") format("ttf");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "FuturaLT";
  src: url("../assets/fonts/FuturaLT/normal/FuturaLT-500.ttf") format("ttf");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "FuturaLT";
  src: url("../assets/fonts/FuturaLT/normal/FuturaLT-600.ttf") format("ttf");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "FuturaLT";
  src: url("../assets/fonts/FuturaLT/normal/FuturaLT-700.ttf") format("ttf");
  font-style: normal;
  font-weight: 700;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "FuturaLT", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  -webkit-backface-visibility: hidden;
}
:root {
  font-size: 10px;
  --main-white: #fff;
  --main-black: #000;
  --main-teal: #379ba5;
  --main-pink: #e48eb6;
  --main-ferozi: #99d0db;
  --main-blue: #34459b;
  --text-color-1: #353535;
  --text-color-2: #5e6364;
  --text-color-3: #748082;
}
html,
body {
  width: 100%;
}
.babybera-app {
  width: 100%;
  position: relative;
}
.box {
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
  padding: 0 5rem;
  position: relative;
}
.rotate-screen {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90000;
  display: none;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rotate-screen p {
  font-size: 1.5rem;
  text-align: center;
  margin: 1.5rem 0 0 0;
  font-weight: 500;
}
.rotate-screen svg {
  font-size: 3.5rem;
}
@media screen and (min-width: 300px) and (max-width: 875px) and (orientation: landscape) {
  .rotate-screen {
    display: flex;
  }
  .page-container {
    display: none;
  }
}
.loading-screen {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
}
.babybera-app a {
  text-decoration: none;
}
.babybera-app button,
.babybera-app button * {
  cursor: pointer;
}
.bb-btn-grd-fill {
  background: linear-gradient(to right, var(--main-ferozi), var(--main-ferozi));
  border-radius: 0.85rem;
  padding: 0.85rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
}
.bb-btn-grd-fill span {
  font-size: 1.65rem;
  position: relative;
  color: var(--main-white);
  z-index: 15;
  transition: 0.35s;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}
.bb-btn-grd-fill::before {
  content: "";
  width: 3rem;
  height: 3rem;
  background-color: var(--main-pink);
  border-radius: 50%;
  position: absolute;
  bottom: -2rem;
  left: 0rem;
  z-index: 10;
  transition: 0.35s;
}
.bb-btn-grd-fill::after {
  content: "";
  width: 3rem;
  height: 3rem;
  background-color: var(--main-blue);
  position: absolute;
  top: -2rem;
  right: 0.5rem;
  z-index: 10;
  transform: rotate(315deg);
  transition: 0.35s;
}
.bb-btn-grd-fill:hover::before {
  bottom: -1.5rem;
  transform: rotate(25deg);
  left: 0.5rem;
}
.bb-btn-grd-fill:hover::after {
  top: -1.5rem;
  transform: rotate(340deg);
  right: 1rem;
}
.bb-btn-grd-fill:hover span {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}
.bb-btn-grd-fill.bb-btn-grd-fill-lg {
  padding: 1.25rem 2.5rem;
}
.bb-btn-grd-fill.bb-btn-grd-fill-lg span {
  font-size: 1.85rem;
}
.bb-btn-grd-fill.bb-btn-grd-fill-lg::before {
  width: 4rem;
  height: 4rem;
}
.bb-btn-grd-fill.bb-btn-grd-fill-lg::after {
  width: 4rem;
  height: 4rem;
}
.bb-btn-grd {
  width: max-content;
  background: linear-gradient(45deg, #62eaff, #ff6dac);
  border-radius: 0.9rem;
  padding: 0.15rem;
  border: 0;
  transition: 0.5s;
  position: relative;
}
.bb-btn-grd.bb-btn-grd-lg span {
  font-size: 1.85rem;
  padding: 1.25rem 2.5rem;
}
/* .bb-btn-grd:hover {
  box-shadow: 0 7px 10px rgba(98, 234, 255, 0.25);
} */
.bb-btn-grd span {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.85rem;
  padding: 0.85rem 2rem;
  cursor: pointer;
  z-index: 15;
  font-size: 1.65rem;
  position: relative;
  background-color: #fff;
}
.light-theme .bb-btn-grd span {
  background-color: #fff;
}
.dark-theme .bb-btn-grd span {
  background: var(--main-bg-1);
}
.dark-theme header .bb-btn-grd span {
  opacity: 0.75;
}
.bb-btn-grd::after {
  content: "";
  width: 85%;
  height: 1rem;
  background: linear-gradient(45deg, #62eaff, #ff6dac);
  transition: 0.5s;
  position: absolute;
  bottom: 0;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, 25%);
  z-index: 5;
  filter: blur(15px);
}
.bb-btn-grd:hover::after {
  opacity: 0.85;
}
.bb-btn-grd span img {
  transition: 0.5s;
  width: 2rem;
  margin: 0 0 0 1rem;
}
.bb-btn-grd:hover img {
  transform: translate(0.5rem, 0);
}
.bb-btn-grd span svg {
  transition: 0.5s;
  width: 2rem;
  margin: 0 0 0 1rem;
  color: var(--main-pink);
}
.bb-btn-grd:hover svg {
  transform: translate(0.5rem, 0);
}
.bb-btn-grd span i {
  font-style: normal;
  background: linear-gradient(45deg, #62eaff, #ff6dac);
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: 700;
  -webkit-text-fill-color: transparent;
}
.section-heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sh-box {
  width: max-content;
  position: relative;
}
.section-heading h3 {
  font-size: 4rem;
  font-weight: 400;
  color: var(--text-color-1);
}
.sh-line-pink {
  width: 57.5%;
  height: 2px;
  background-color: var(--main-pink);
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(0, 1.15rem);
}
.sh-line-blue {
  width: 57.5%;
  height: 2px;
  background-color: var(--main-ferozi);
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(0, 0.25rem);
}
.grd-text {
  background: linear-gradient(45deg, #62eaff, #ff6dac);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
#dropArea {
  width: 250px;
  height: 250px;
  position: absolute;
  top: -100%;
  right: 0;
}
.modal {
  backdrop-filter: blur(15px);
}
.modal-content {
  border-radius: 1.5rem !important;
  overflow: hidden;
  border: 0 !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
.modal-body {
  padding: 0 !important;
}
.modal-backdrop {
  background-color: rgba(255, 255, 255, 1) !important;
}
.connect-wallet-modal {
  width: 100%;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.connect-wallet-modal-box {
  width: 100%;
  max-width: 500px;
  padding: 2.5rem;
  background-color: var(--main-white);
}
.wallet-boxes {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f8f9;
  padding: 1rem 2rem;
  border-radius: 0.75rem;
  cursor: pointer;
  transition: 0.35s;
}
.wallet-boxes:hover {
  background-color: #f1f1f1;
}
.wallet-boxes img {
  width: 2.75rem;
  object-fit: contain;
}
.wallet-boxes h5 {
  font-size: 1.75rem;
  font-weight: 500;
  color: var(--text-color-1);
}
.cwm-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cwm-header h3 {
  font-size: 3rem;
  font-weight: 500;
  color: var(--text-color-1);
}
.cwm-header svg {
  font-size: 2.5rem;
  color: var(--text-color-1);
  cursor: pointer;
}
.cwm-divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 1rem 0 2.5rem 0;
}
.wallet-boxed-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 1.5rem;
}
.nav-item {
  width: 100%;
}
.nav-link {
  padding: 1rem 2.5rem !important;
  text-align: center !important;
  font-size: 1.75rem !important;
  color: var(--main-black) !important;
  border-radius: 1rem !important;
  border: 0 !important;
}
.nav-link.active {
  background-color: rgba(228, 142, 182, 1) !important;
  border-radius: 1rem !important;
  color: var(--main-black) !important;
}
.nav-tabs {
  display: flex !important;
  border: 0 !important;
  flex-wrap: nowrap !important;
  background-color: rgba(228, 142, 182, 0.5);
  border-radius: 1rem;
  overflow: hidden;
  max-width: 500px;
  margin: 0 auto;
}
.apr-modal-content {
  width: 100%;
}
.apr-modal-content table {
  width: 100%;
}
.apr-modal-content table th {
  font-size: 1.5rem;
  padding: 0.25rem 0;
  font-weight: 700;
  color: var(--text-color-1);
}
.apr-modal-content table td {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--text-color-1);
  padding: 0.25rem 0;
}
.apr-modal-content p {
  font-size: 1.35rem;
  color: var(--text-color-3);
  margin: 2.5rem 0 0 0;
}
@media screen and (max-width: 1000px) {
  .box {
    padding: 0 3.5rem;
  }
}
@media screen and (max-width: 750px) {
  .cwm-header h3 {
    font-size: 2.25rem;
  }
  .connect-wallet-modal-box {
    padding: 1.5rem;
  }
  .cwm-header svg {
    font-size: 2.25rem;
  }
  .modal.show .modal-dialog {
    margin: 0 2rem;
  }
}
